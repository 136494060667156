import React from 'react';
import ReactDOM from 'react-dom';

import SupportBrowsers from './components/organisms/SupportedBrowsers';

if (window?.navigator?.userAgent.match(/MSIE|Trident/) !== null) {
  const container = document.getElementById('supportedBrowsers');

  ReactDOM.render(<SupportBrowsers />, container);
}

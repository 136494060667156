import React from 'react';
import cls from 'classnames';
import { l10n } from '@ott/l10n';
import { Container, Row, Col } from '@ott/grid';

import styles from './SupportedBrowsers.scss';

const SupportedBrowsers = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <Row>
          <Col desktop={12} tablet={12} mobile={4} className={styles.container}>
            <div className={styles.title}>{l10n('supportBrowsers.title')}</div>
            <div>{l10n('supportBrowsers.description')}</div>
            <div className={styles.browsersList}>
              <a
                className={cls(styles.browserItem, styles.browserEdge)}
                href="https://www.microsoft.com/ru-ru/edge"
                target="_blank"
              >
                <div className={styles.browserTitle}>Microsoft Edge</div>
                <span>{l10n('supportBrowsers.action')}</span>
              </a>
              <a
                className={cls(styles.browserItem, styles.browserChrome)}
                href="https://www.google.ru/chrome/"
                target="_blank"
              >
                <div className={styles.browserTitle}>Google Chrome</div>
                <span>{l10n('supportBrowsers.action')}</span>
              </a>
              <a
                className={cls(styles.browserItem, styles.browserFirefox)}
                href="https://www.mozilla.org/ru/firefox/"
                target="_blank"
              >
                <div className={styles.browserTitle}>Mozilla Firefox</div>
                <span>{l10n('supportBrowsers.action')}</span>
              </a>
              <a
                className={cls(styles.browserItem, styles.browserOpera)}
                href="https://www.opera.com/ru"
                target="_blank"
              >
                <div className={styles.browserTitle}>Opera Software</div>
                <span>{l10n('supportBrowsers.action')}</span>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SupportedBrowsers;
